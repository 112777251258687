<template>
  <NuxtLayout name="small">
    <DaisyCard class="w-full bg-base-100">
      <h2 class="card-title">Dang</h2>
      <p>
        Something went horribly wrong. An error report has been send so we can
        fix what blew up. Please try this action again later, or shoot us an
        email.
      </p>
      <code
        v-if="error"
        class="block whitespace-pre max-h-64 w-full rounded-md bg-base-200 text-xs overflow-scroll p-4"
      >
        {{ trace }}
      </code>
      <DaisyButton
        v-if="error && $route.path !== '/'"
        title="Go back home"
        color="secondary"
        class="mt-2"
        block
        @click="() => clearError({ redirect: '/' })"
      >
        Go back home
      </DaisyButton>
    </DaisyCard>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const error = useError();
const trace = computed(() => {
  return JSON.stringify(toRaw(toValue(error)), null, 2);
});

useHead({
  title: "Error - Dienst 3",
});
</script>
