import type { RouteLocation } from "#vue-router";

import type { Account } from "~/server/lib/db/account";

export default defineNuxtPlugin(async () => {
  const currentRoute = useRoute();
  const accountStore = await useAccountStore();
  const { loggedIn, current: account } = storeToRefs(accountStore);
  const redirectTo = useState(
    "authRedirect",
    () => currentRoute.query.o?.toString() ?? "/",
  );

  const getAuthRoute = (route: RouteLocation): string | undefined => {
    // Logged in, but in an authentication route.
    // Sending the user back home.
    if (loggedIn.value && route.meta.pageType === "auth") {
      return redirectTo.value;
    }

    // On an route which requires an account, but there is no account available.
    // Sending the user to the login page.
    if (!account.value && route.meta.pageType === "account") {
      redirectTo.value = route.path;
      return `/auth/login?o=${currentRoute.path}`;
    }

    // TODO: checks if there is an association selected.
  };

  addRouteMiddleware("auth", (to) => getAuthRoute(to), {
    global: true,
  });

  watch(
    account,
    async (accountData) => {
      const bugsnag = useBugsnag();
      bugsnag.setUser?.(accountData?.id, accountData?.email, accountData?.name);

      const newRoute = getAuthRoute(currentRoute);
      if (newRoute) {
        await navigateTo(newRoute);
      }
    },
    { immediate: true },
  );

  if (process.client) {
    const channel = new BroadcastChannel("dienst3-authstate");
    channel.onmessage = (newAccount: MessageEvent<Account | undefined>) => {
      if (account.value?.id === newAccount.data?.id) {
        return;
      }

      accountStore.current = newAccount.data;
    };

    watch(account, (account) => {
      channel.postMessage(toRaw(account));
    });
  }
});
