import type {
  AvailableRouterMethod,
  NitroFetchOptions,
  NitroFetchRequest,
  TypedInternalResponse,
} from "nitropack";
import type { FetchError } from "ofetch";
import type { Ref } from "vue";

import type { UseFetchOptions } from "#app";

import { type ApiError, ErrorCode } from "../server/lib/error";

/**
 * Send an API request with error handling.
 * This extends the build-in `$fetch` function.
 * @param url The URL to request.
 * @param options Any other fetch options.
 * @param handlers The error handlers for this request.
 * @returns An object with either an error or an data entry.
 */
export async function $request<
  R extends Extract<NitroFetchRequest, string>,
  M extends AvailableRouterMethod<R> = AvailableRouterMethod<R>,
  T extends TypedInternalResponse<R, unknown, M> = TypedInternalResponse<
    R,
    unknown,
    M
  >,
>(url: R, options: ApiRequestOptions<R, M>) {
  try {
    const csrfToken = process.client ? (window as any)._csrfToken : "";
    const cookies = useRequestHeaders(["cookie"]);

    const res = await $fetch<T>(url, {
      ...options,
      body: unwrapRefs(options?.body),
      headers: {
        ...options.headers,
        ...cookies,
        "csrf-token": csrfToken,
      },
    });

    return { data: res as T, error: undefined };
  } catch (e) {
    const err = (e as FetchError).data.data;

    return {
      error: isApiError(err)
        ? err
        : apiError(ErrorCode.InternalError, undefined),
      data: undefined,
    };
  }
}

/**
 * Send a request to the api.
 * This is an extension on the `useFetch` hook, and can be used in the same way (with error handles).
 * The result of the error handlers are returned in the `error` state.
 * @param url The URL to request.
 * @param options Any other options, extends fetch options.
 * @param handlers The error handlers for this request.
 * @returns a useFetch hook response.
 */
export async function useRequest<
  R extends Extract<NitroFetchRequest, string> = string & {},
  M extends AvailableRouterMethod<R> = AvailableRouterMethod<R>,
  ResT extends TypedInternalResponse<R, unknown, M> = TypedInternalResponse<
    R,
    unknown,
    M
  >,
  DataT = ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null,
>(
  url: R,
  options: UseFetchOptions<ResT, DataT, PickKeys, DefaultT, R, M> = {},
) {
  const error = useState<ApiError<ErrorCode> | undefined>();
  const csrfToken = process.client ? (window as any)._csrfToken : "";
  const cookies = useRequestHeaders(["cookie"]);

  const res = await useFetch(url, {
    ...options,
    body: unwrapRefs(options?.body),
    headers: {
      ...options.headers,
      ...cookies,
      "csrf-token": csrfToken,
    },
    onRequest() {
      error.value = undefined;
    },
    onResponseError(ctx) {
      const err = ctx.response._data.data;
      error.value = isApiError(err)
        ? err
        : apiError(ErrorCode.InternalError, undefined);
    },
  });

  return { ...res, error };
}

export const isErrorCode = <E extends ErrorCode>(
  code: E,
  err?: ApiError<ErrorCode>,
): err is ApiError<E> => !!err && err.code === code;

export interface ApiRequestOptions<
  R extends NitroFetchRequest,
  M extends AvailableRouterMethod<R>,
> extends NitroFetchOptions<R, M> {
  method: M;
  body?: Record<string, any>;
  onSuccess?: () => void;
}

export interface ApiResponse<T, E> {
  data: Ref<T | null>;
  error: Ref<E | null>;
  requesting: Ref<boolean>;
  send: () => void;
}

/**
 * Unwraps any references and proxies on the provided value.
 * This is recursive, so works on deep objects.
 * @param v The value with possible proxies and refs.
 * @returns the same value without these wrappers.
 */
const unwrapRefs = <T>(v: Ref<T> | T): T => {
  if (isRef(v)) {
    // remove the reference and recurse.
    return unwrapRefs(v.value);
  }

  if (isProxy(v)) {
    // Remove the proxy and recurse.
    return unwrapRefs(toRaw(v));
  }

  if (Array.isArray(v)) {
    // The value is an array, so recurse on all values.
    return v.map((e) => unwrapRefs(e)) as T;
  }

  if (v && typeof v === "object") {
    // The value is an object, recurse on all values.
    return Object.fromEntries(
      Object.entries(v).map(([k, v]) => [k, unwrapRefs(v)]),
    ) as T;
  }

  // The value is simple (number, string), and can be returned as is.
  return v;
};

type KeysOf<T> = Array<
  T extends T ? (keyof T extends string ? keyof T : never) : never
>;
