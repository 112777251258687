import { default as _91_46_46_46404_93CskmQFaByvMeta } from "/vercel/path0/pages/[...404].vue?macro=true";
import { default as _91_46_46_46notfound_93LmKzJGNDkWMeta } from "/vercel/path0/pages/auth/[...notfound].vue?macro=true";
import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as magicPygF3PoQaRMeta } from "/vercel/path0/pages/auth/magic.vue?macro=true";
import { default as registerUZDCsj62s6Meta } from "/vercel/path0/pages/auth/register.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46notfound_93gowGcHKAyjMeta } from "/vercel/path0/pages/settings/[...notfound].vue?macro=true";
import { default as members5QE6ulFp3DMeta } from "/vercel/path0/pages/settings/association/directory/members.vue?macro=true";
import { default as indexi2pzW1TbRSMeta } from "/vercel/path0/pages/settings/association/index.vue?macro=true";
import { default as associationKw0lZjhJ78Meta } from "/vercel/path0/pages/settings/association.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as membershipsX1F4uPNJQ6Meta } from "/vercel/path0/pages/settings/memberships.vue?macro=true";
import { default as securityY4oknXfXXwMeta } from "/vercel/path0/pages/settings/security.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
export default [
  {
    name: _91_46_46_46404_93CskmQFaByvMeta?.name ?? "404",
    path: _91_46_46_46404_93CskmQFaByvMeta?.path ?? "/:404(.*)*",
    meta: _91_46_46_46404_93CskmQFaByvMeta || {},
    alias: _91_46_46_46404_93CskmQFaByvMeta?.alias || [],
    redirect: _91_46_46_46404_93CskmQFaByvMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...404].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46notfound_93LmKzJGNDkWMeta?.name ?? "auth-notfound",
    path: _91_46_46_46notfound_93LmKzJGNDkWMeta?.path ?? "/auth/:notfound(.*)*",
    meta: _91_46_46_46notfound_93LmKzJGNDkWMeta || {},
    alias: _91_46_46_46notfound_93LmKzJGNDkWMeta?.alias || [],
    redirect: _91_46_46_46notfound_93LmKzJGNDkWMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/[...notfound].vue").then(m => m.default || m)
  },
  {
    name: loginILQdBdZzRsMeta?.name ?? "auth-login",
    path: loginILQdBdZzRsMeta?.path ?? "/auth/login",
    meta: loginILQdBdZzRsMeta || {},
    alias: loginILQdBdZzRsMeta?.alias || [],
    redirect: loginILQdBdZzRsMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: magicPygF3PoQaRMeta?.name ?? "auth-magic",
    path: magicPygF3PoQaRMeta?.path ?? "/auth/magic",
    meta: magicPygF3PoQaRMeta || {},
    alias: magicPygF3PoQaRMeta?.alias || [],
    redirect: magicPygF3PoQaRMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/magic.vue").then(m => m.default || m)
  },
  {
    name: registerUZDCsj62s6Meta?.name ?? "auth-register",
    path: registerUZDCsj62s6Meta?.path ?? "/auth/register",
    meta: registerUZDCsj62s6Meta || {},
    alias: registerUZDCsj62s6Meta?.alias || [],
    redirect: registerUZDCsj62s6Meta?.redirect,
    component: () => import("/vercel/path0/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name ?? undefined,
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: _91_46_46_46notfound_93gowGcHKAyjMeta?.name ?? "settings-notfound",
    path: _91_46_46_46notfound_93gowGcHKAyjMeta?.path ?? ":notfound(.*)*",
    meta: _91_46_46_46notfound_93gowGcHKAyjMeta || {},
    alias: _91_46_46_46notfound_93gowGcHKAyjMeta?.alias || [],
    redirect: _91_46_46_46notfound_93gowGcHKAyjMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/[...notfound].vue").then(m => m.default || m)
  },
  {
    name: associationKw0lZjhJ78Meta?.name ?? undefined,
    path: associationKw0lZjhJ78Meta?.path ?? "association",
    meta: associationKw0lZjhJ78Meta || {},
    alias: associationKw0lZjhJ78Meta?.alias || [],
    redirect: associationKw0lZjhJ78Meta?.redirect,
    component: () => import("/vercel/path0/pages/settings/association.vue").then(m => m.default || m),
    children: [
  {
    name: members5QE6ulFp3DMeta?.name ?? "settings-association-directory-members",
    path: members5QE6ulFp3DMeta?.path ?? "directory/members",
    meta: members5QE6ulFp3DMeta || {},
    alias: members5QE6ulFp3DMeta?.alias || [],
    redirect: members5QE6ulFp3DMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/association/directory/members.vue").then(m => m.default || m)
  },
  {
    name: indexi2pzW1TbRSMeta?.name ?? "settings-association",
    path: indexi2pzW1TbRSMeta?.path ?? "",
    meta: indexi2pzW1TbRSMeta || {},
    alias: indexi2pzW1TbRSMeta?.alias || [],
    redirect: indexi2pzW1TbRSMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/association/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKIRFIU1ID4Meta?.name ?? "settings",
    path: indexKIRFIU1ID4Meta?.path ?? "",
    meta: indexKIRFIU1ID4Meta || {},
    alias: indexKIRFIU1ID4Meta?.alias || [],
    redirect: indexKIRFIU1ID4Meta?.redirect,
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membershipsX1F4uPNJQ6Meta?.name ?? "settings-memberships",
    path: membershipsX1F4uPNJQ6Meta?.path ?? "memberships",
    meta: membershipsX1F4uPNJQ6Meta || {},
    alias: membershipsX1F4uPNJQ6Meta?.alias || [],
    redirect: membershipsX1F4uPNJQ6Meta?.redirect,
    component: () => import("/vercel/path0/pages/settings/memberships.vue").then(m => m.default || m)
  },
  {
    name: securityY4oknXfXXwMeta?.name ?? "settings-security",
    path: securityY4oknXfXXwMeta?.path ?? "security",
    meta: securityY4oknXfXXwMeta || {},
    alias: securityY4oknXfXXwMeta?.alias || [],
    redirect: securityY4oknXfXXwMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/security.vue").then(m => m.default || m)
  }
]
  }
]