import type { Account } from "~/server/lib/db/account";

export const useAccountStore = async () => {
  const store = defineStore("account", () => {
    /**
     * The current active account.
     */
    const current = useState<Account | undefined>(() => undefined);

    /**
     * Returns if we are currently logged in.
     */
    const loggedIn = computed(() => !!current.value?.id);

    /**
     * Reload the account.
     */
    const reload = async () => {
      const { data, error } = await $request("/api/account", { method: "get" });

      if (data) {
        current.value = data;
      } else if (isErrorCode(ErrorCode.Unauthorized, error)) {
        current.value = undefined;
      } else {
        throw error;
      }
    };

    /**
     * Update information about the current account.
     * @param fields to update.
     * @returns The error if the request failed.
     */
    const update = async (fields: { name?: string }) => {
      const { data, error } = await $request("/api/account", {
        method: "patch",
        body: fields,
      });

      if (error) {
        return error;
      }

      current.value = data;
    };

    /**
     * Log out the current account.
     * @returns The api error, if it exists.
     */
    const logout = async () => {
      const { error } = await $request("/api/auth/session", {
        method: "delete",
      });

      if (error) {
        return error;
      }

      current.value = undefined;
    };

    /**
     * Delete the current account.
     * @returns The error if the request failed.
     */
    const deleteCurrent = async () => {
      const { error } = await $request("/api/account", {
        method: "delete",
      });

      if (error) {
        return error;
      }

      current.value = undefined;
    };

    return {
      current,
      loggedIn,
      reload,
      update,
      logout,
      deleteCurrent,
    };
  });

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
  }

  const s = store();
  const initialized = useState(() => false);

  if (initialized.value === false) {
    initialized.value = true;
    await s.reload();
  }

  return s;
};
