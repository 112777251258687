import revive_payload_client_WP73BcEMoG from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TsUw13ySK2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cHQZx8jY7C from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_iduwYJvgBd from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_XvYIS0USew from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_gUBhiVcJvj from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_I6G60NBIXy from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Y00ChtiT1Q from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import chunk_reload_client_9b2kAkniS0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.0_3deaa5576ccbfaf05268e5bac8380424/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import theme_QvfjG8C98z from "/vercel/path0/node_modules/.pnpm/@dienst3+nuxt-daisyui@0.24.0_nuxt@3.10.0/node_modules/@dienst3/nuxt-daisyui/plugins/theme.ts";
import account_LeA72UVdGn from "/vercel/path0/plugins/account.ts";
export default [
  revive_payload_client_WP73BcEMoG,
  unhead_TsUw13ySK2,
  router_cHQZx8jY7C,
  payload_client_iduwYJvgBd,
  check_outdated_build_client_XvYIS0USew,
  plugin_vue3_gUBhiVcJvj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_I6G60NBIXy,
  plugin_Y00ChtiT1Q,
  chunk_reload_client_9b2kAkniS0,
  theme_QvfjG8C98z,
  account_LeA72UVdGn
]