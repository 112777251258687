import type { Association } from "~/server/lib/db/association";

export const useAssociationStore = async () => {
  const store = defineStore("association", () => {
    /**
     * The ID of the currently selected association.
     */
    const currentId = useCookie("dienst3-association", {
      default: () => "",
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      sameSite: "lax",
    });
    /**
     * All associations.
     */
    const associations = useState<Association[]>(() => []);

    /**
     * Return the currently selected association.
     */
    const current = computed(
      () => associations.value?.find((i) => i.id === currentId.value),
    );

    /**
     * Reload the associations.
     */
    const reload = async () => {
      const { data, error } = await $request("/api/association", {
        method: "get",
      });

      if (data) {
        associations.value = data;
      } else {
        throw error;
      }
    };

    /**
     * Create a new association.
     * @param name The name to use.
     * @returns an object with the new association, or an error object.
     */
    const create = async (name: string) => {
      const { data, error } = await $request("/api/association", {
        method: "post",
        body: {
          name,
        },
      });

      if (error) {
        return { error };
      }

      associations.value = [...(associations.value ?? []), data];
      currentId.value = data.id;
      return { data };
    };

    return {
      current,
      currentId,
      associations,
      reload,
      create,
    };
  });

  if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot));
  }

  const s = store();
  const initialized = useState(() => false);

  if (initialized.value === false) {
    initialized.value = true;
    await s.reload();
  }

  return s;
};
