export default [
  {
    "light": {
      "primary": "#6c00ff",
      "primary-content": "#fafafa",
      "secondary": "#ff6b35",
      "accent": "#01f5ff",
      "neutral": "#ffffff",
      "base-100": "#f3f3f3"
    }
  },
  {
    "dark": {
      "color-scheme": "dark",
      "primary": "#6c00ff",
      "primary-content": "#fafafa",
      "secondary": "#ff6b35",
      "accent": "#01f5ff",
      "neutral": "#2a323c",
      "base-100": "#1d232a"
    }
  }
]