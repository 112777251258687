<template>
  <NuxtLayout>
    <div>
      <SpeedInsights />
      <NuxtLoadingIndicator color="#ff6b35" />
      <NuxtPage />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { SpeedInsights } from "@vercel/speed-insights/nuxt";

useSeoMeta({
  title: "Dienst 3",
  ogTitle: "Dienst 3",
  description: "Membership system made easy",
  ogDescription: "Membership system made easy",
});
</script>
